header {
  padding: 20px 0;
  margin: 0;

  a {
    img {
      height: 70px;
    }
  }
}
